export default [
    {
        name: '首页',
        router: '/',
    },
    // {
    //     name: '设计规范',
    //     router: '/designs',
    // },
    {
        name: '组件库',
        router: '/components',
    },
];
