<template>
    <div :class="$style.root">
        <ot-loading :class="$style.loading" type="push" size="big" label="2O3T Loading"></ot-loading>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" module>
.root {
    position: absolute;
    top: 6.4rem;
    left: 0rem;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .6);
    margin: auto;

    .loading {
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>

